import React from "react"
import Helmet from "react-helmet"
import TermsOfService from "@tightrope/footerlinks/terms-of-service"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"


export default function Terms() {
  return(

    <HomepageLayout>
        <Helmet data={JSONData}><title>Terms of Service - breakingnewstab.com</title></Helmet>
    <TermsOfService data={JSONData}>    <Helmet>Test</Helmet></TermsOfService>
    </HomepageLayout>
  )
}
